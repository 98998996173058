import axios from "axios";
import { indonesia_payment } from "../../js/path";

const apiEndpoint = process.env.VUE_APP_INDONESIA_PAYMENT;

export default {
  data() {
    return {
      pageNo: null,
      submitted: false,
      status: false,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      tableData: [],
      products: [],
      currentPage: 1,
      activeTab: "all",
      params: "",
      key: 0,
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "product_name",
          label: "Product Name",
        },
        {
          key: "name",
          label: "Name",
        },
        {
          key: "slug",
          label: "Slug",
        },
        {
          key: "price",
          label: "Price",
        },
        {
          key: "credits",
          label: "Credits",
        },
        {
          key: "validity_in_days",
          label: "Valid Days",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "edit",
          label: "Edit",
        },
      ],
      form: {
        id: "",
        product_id: "",
        name: "",
        price: "",
        slug: "",
        credits: null,
        validity_in_days: null,
        status: false,
        currency: "",
        description: "",
        sort_by: "",
      },
    };
  },
  methods: {
    searchFor() {
      if (this.filter.length > 1) this.fetchData();
      else if (this.filter.length == 0) this.fetchData();
    },

    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData();
        }
      } else if (this.filter.length == 0) this.fetchData();
    },

    filterPage() {
      if (this.pageNo.length > 0) {
        this.currentPage = this.pageNo;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    changestatus() {
      if (this.status) {
        this.status = false;
      } else {
        this.status = true;
      }
    },

    async fetchProducts() {
      const url = indonesia_payment?.getPlanProduct;
      const data = await this.getRequest(`${apiEndpoint}${url}`);
      if (data.status) {
        this.products = data.response;
      }
    },

    async fetchData(txt) {
      this.$store.commit("loader/updateStatus", true);
      if (txt == "active") {
        this.activeTab = txt;
      } else if (txt == "in_active") {
        this.activeTab = txt;
      } else if (txt == "all") {
        this.activeTab = txt;
      }
      let request = `${indonesia_payment?.getplans}?page=${
        this.currentPage ?? 1
      }&q=${this.filter ?? ""}&filter=${txt ?? ""}`;
      try {
        const res = await axios.get(`${apiEndpoint}${request}`);
        if (res) {
          this.tableData = res?.data?.response;
        }
        this.$store.commit("loader/updateStatus", false);
      } catch (err) {
        this.$store.commit("toast/updateStatus"),
          {
            status: true,
            icon: "error",
            title: "somthing went wrong",
          };
        this.$store.commit("loader/updateStatus", false);
      }
    },

    async updateStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = `${apiEndpoint}${indonesia_payment?.updatePathStatus}/${id}`;
        const data = await axios.post(url, {
          status: !this.tableData.data[index].status,
        });
        if (data.status) {
          this.tableData.data[index].status =
            !this.tableData.data[index].status;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "Status updated successfully",
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async submitData() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields",
          });
          return false;
        }

        let dataAppend = {};
        for (var key in this.form) {
          if (key == "id") continue;
          dataAppend[key] = this.form[key];
        }
        this.$store.commit("loader/updateStatus", true);
        if (this.$route.name == "edit-plans") {
          let url = `${apiEndpoint}${indonesia_payment?.getplans}/${this.form.id}`;
          const request = await axios.put(url, dataAppend);
          const response = request?.data;
          if (response?.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: response?.message,
            });
            this.$router.push("/plans");
          }
        } else {
          let url = `${apiEndpoint}${indonesia_payment?.getplans}`;
          const request = await axios.post(url, dataAppend);
          const response = request?.data;
          if (response?.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: response?.message,
            });
            this.$router.push("/plans");
          }
        }
      } catch (err) {
        let errorMessage = err.response?.data?.response?.slug
          ? err.response.data.response.slug
          : err.response?.data?.response?.price
          ? err.response.data.response.price
          : err.response?.data?.response?.validity_in_days
          ? err.response.data.response.validity_in_days
          : "Please try again!";
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: errorMessage,
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async fetchPlan(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const request = `${indonesia_payment?.getplans}/${id}`;
        const response = await axios.get(`${apiEndpoint}${request}`);
        const res = response?.data;
        if (res) {
          const responseData = res.response;
          if (responseData.id !== "" && responseData.id !== null) {
            this.form.id = responseData.id;
          }
          if (
            responseData.product_id !== "" &&
            responseData.product_id !== null
          ) {
            this.form.product_id = responseData.product_id;
          }
          if (responseData.name !== "" && responseData.name !== null) {
            this.form.name = responseData.name;
          }
          if (responseData.slug !== "" && responseData.slug !== null) {
            this.form.slug = responseData.slug;
          }
          if (responseData.price !== "" && responseData.price !== null) {
            this.form.price = responseData.price;
          }
          if (responseData.credits !== "" && responseData.credits !== null) {
            this.form.credits = responseData.credits;
          }
          if (
            responseData.validity_in_days !== "" &&
            responseData.validity_in_days !== null
          ) {
            this.form.validity_in_days = responseData.validity_in_days;
          }

          if (responseData.currency !== "" && responseData.currency !== null) {
            this.form.currency = responseData.currency;
          }

          if (
            responseData.description !== "" &&
            responseData.description !== null
          ) {
            this.form.description = responseData.description;
          }
          if (responseData.sort_by !== "" && responseData.sortBy !== null) {
            this.form.sort_by = responseData.sort_by;
          }

          if (responseData.status === 1) {
            this.form.status = true;
          } else {
            this.form.status = false;
          }
        }
        this.$store.commit("loader/updateStatus", false);
      } catch (err) {
        console.error("Error fetching payment gateway:", err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Something went wrong",
        });
        this.$store.commit("loader/updateStatus", false);
      }
    },
  },

  watch: {
    currentPage: {
      handler: function (v) {
        if (v != "" && v != null) {
          this.fetchData(this.activeTab);
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  created() {
    if (this.$route.name == "add-plans" || this.$route.name == "edit-plans") {
      this.fetchProducts();
      if (this.$route.name == "edit-plans") {
        this.fetchPlan(this.$route.params.id);
      }
    } else {
      this.fetchData();
    }
  },
};
